import Vue from "vue";
import Router from "vue-router";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: () => import("./views/home"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/login",
      component: () => import("./views/login"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: false,
      },
    },
    {
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: false,
      },
    },
    {
      path: "/logout",
      redirect: "/login",
    },
    {
      path: "/register",
      component: () => import("./views/register"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: false,
      },
    },
    {
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: false,
      },
    },
    {
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: false,
      },
    },
    {
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: false,
      },
    },
    {
      path: "/account",
      component: () => import("./views/account/account"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/account/documents",
      component: () => import("./views/account/documents"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/account/address",
      component: () => import("./views/account/address"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/account/password/security",
      component: () => import("./views/account/password-security"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/account/password/security/:token",
      component: () => import("./views/account/password-security"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/account/pix",
      component: () => import("./views/account/pix"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/contracts",
      component: () => import("./views/contracts/list"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/contracts/:id",
      component: () => import("./views/contracts/view"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/wallet/extract",
      component: () => import("./views/wallet/extract"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/wallet/withdrawal",
      component: () => import("./views/wallet/withdrawal"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/wallet/withdrawal/history",
      component: () => import("./views/wallet/withdrawal-history"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/network/indicados",
      component: () => import("./views/network/indicados"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/network/equipe",
      component: () => import("./views/network/equipe"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/network/matriz",
      component: () => import("./views/network/matriz"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/reports/indicacao",
      component: () => import("./views/reports/indicacao"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      name: "Loja",
      path: "/store",
      redirect: "/store/featured",
    },
    {
      name: "Loja › Destaques",
      path: "/store/featured",
      component: () => import("./views/store/featured"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      name: "Loja › Categoria",
      path: "/store/category/:id",
      component: () => import("./views/store/category"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      name: "Loja › Buscar",
      path: "/store/search",
      component: () => import("./views/store/search"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      name: "Loja › Carrinho",
      path: "/store/cart",
      component: () => import("./views/store/cart"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      name: "Loja › Pedidos",
      path: "/store/orders",
      component: () => import("./views/store/orders"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      name: "Loja › Pedido",
      path: "/store/order/:id",
      component: () => import("./views/store/order"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      name: "Loja › Franquias",
      path: "/store/franchises",
      component: () => import("./views/store/franchises"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/faq",
      component: () => import("./views/faq"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/courses",
      component: () => import("./views/courses/list"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/courses/:course",
      component: () => import("./views/courses/view"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/courses/:course/:module/:lesson",
      component: () => import("./views/courses/view"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/products",
      component: () => import("./views/products/index"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/products/buy",
      component: () => import("./views/products/buy"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: true,
      },
    },
    {
      path: "/404",
      component: () => import("./views/404"),
      meta: {
        title: process.env.VUE_APP_TITLE,
        authRequired: false,
      },
    },
    {
      path: "*",
      redirect: "/register*",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  document.title = to.meta.title;
  next();

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          if (
            response.data.user.contracts &&
            response.data.user.contracts.length > 0 &&
            to.path != "/contracts/:id"
          ) {
            response.data.user.contracts.forEach(con => {
              return next("/contracts/" + con.id);
            })
          } else if (
            response.data.order &&
            response.data.order.id &&
            to.path != "/store/orders"
          ) {
            return next("/store/order/" + response.data.order.id);
          }
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  } else {
    next();
  }
});

export default router;
